import React from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import "./style.blog-list.scss"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath, pages, currentPage },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout>
        <Seo
          title="行业洞察_装箱装柜软件_外贸装柜软件-蓝胖智汇"
          keywords="外贸装柜软件,蓝胖智汇"
          description="蓝胖智汇专注自研装箱装柜软件、外贸装柜软件，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
        />
        <div className="relative">
          <StaticImage
            src="./banner.png"
            alt="业洞察_装箱装柜软件_外贸装柜软件-蓝胖智汇"
            className="w-full"
          />
          <h1 className="absolute top-28 left-24 text-white">行业洞察</h1>
        </div>
        <p className="h-[300px] flex justify-center items-center">暂无新闻</p>
      </Layout>
    )
  }

  return (
    <Layout>
      <Seo
        title="行业洞察_装箱装柜软件_外贸装柜软件-蓝胖智汇"
        keywords="外贸装柜软件,蓝胖智汇"
        description="蓝胖智汇专注自研装箱装柜软件、外贸装柜软件，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="./banner.png"
          alt="行业洞察_装箱装柜软件_外贸装柜软件-蓝胖智汇"
          className="w-full"
        />
        <h1 className="absolute top-28 left-24 text-white">行业洞察</h1>
      </div>

      <div className="max-w-[1280px] mt-10 mx-auto blog-list">
        <ol style={{ listStyle: `none` }} className="divide-y ">
          {posts.map(post => {
            const title = post.title
            return (
              <li key={post.uri} className="py-4">
                <div className="relative w-[100%] pl-[25%] h-[100%]">
                  {post.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData && (
                    <Link
                      to={post.uri}
                      className="absolute top-0 left-4 w-[22%] h-[100%] hover:cursor-pointer "
                    >
                      <GatsbyImage
                        image={
                          post.featuredImage?.node?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        }
                        alt={
                          post.featuredImage?.node?.localFile?.childImageSharp
                            ?.alt ?? ""
                        }
                        style={{ height: "100%" }}
                      />
                    </Link>
                  )}
                  <header>
                    <h1 className="py-3 text-lg ">
                      <Link
                        to={`/news/${post.id}`}
                        className="text-gray-700 hover:text-blue-500"
                        itemProp="url"
                      >
                        <span
                          itemProp="headline"
                          className="block whitespace-nowrap"
                        >
                          {parse(title)}
                        </span>
                      </Link>
                    </h1>
                  </header>
                  <section
                    itemProp="description"
                    className="excerpt text-gray-500 my-2 min-h-[40px] text-16"
                  >
                    {parse(post.excerpt)}
                  </section>
                  <small className="text-sm text-gray-500">{post.date}</small>
                </div>
              </li>
            )
          })}
        </ol>
        <div className="flex gap-2 justify-center items-center m-auto mt-12">
          <GoTo to={previousPagePath} name="上一页" />
          {Array.from(Array(pages).keys()).map(num => (
            <GoTo
              to={num === 0 ? "/news" : `/news/${num + 1}`}
              name={num + 1}
              currentPage={currentPage}
            />
          ))}
          <GoTo to={nextPagePath} name="下一页" />
        </div>
      </div>
    </Layout>
  )
}

const GoTo = ({ to, name, currentPage }) => (
  <Link
    to={to}
    className={`block border-2 transition-all hover:cursor-pointer hover:bg-[#3055a4] hover:text-white 
                hover:border-[#3055a4] text-gray-700 rounded-sm px-3 leading-8 text-sm
              ${
                name === currentPage + 1
                  ? " bg-[#3055a4] border-[#3055a4] text-white"
                  : ""
              }
              `}
  >
    {name}
  </Link>
)

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        excerpt
        uri
        date(formatString: "YYYY-MM-DD")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 30
                  placeholder: TRACED_SVG
                  width: 280
                  transformOptions: { fit: COVER }
                )
              }
            }
          }
        }
      }
    }
  }
`
